/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from '@emotion/styled'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import React, { useState, useEffect }  from "react"
import { window, exists } from "browser-monads"
import { navigate } from 'gatsby-link'
import axios from "axios"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from "gatsby"
import Sidebar from "@lekoarts/gatsby-theme-cara/src/components/sidebar"
import SEO from "@lekoarts/gatsby-theme-cara/src/components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook, faPhoneSquareAlt, faEnvelopeOpenText, faCashRegister, faTrophy, faAd, faDesktop,faCommentDollar,faSearch, faEnvelopeSquare, faSms, faPeopleArrows, faBlog } from '@fortawesome/free-solid-svg-icons'
import Sitemap from "@lekoarts/gatsby-theme-cara/src/components/sitemap"

const Input = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
`


const SubmitButton = styled.input`
  border: 0px;
    padding: 10px;
    background: #3caf7b;
    color: white;
    font-size: 1em;
    font-weight: 800;
`   

const LongArrowLeft = styled.div`
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid gray;
  border-left: 2px solid gray ;
  transform: rotate(-45deg);
     &:after{
    content: "";
    display: block;
    width: 2px;
    height: 45px;
    background-color: gray;
    transform: rotate(-45deg) translate(15px, 4px);
    left: 0;
    top: 0;
  }
`

const ImageWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  align-content:space-around;
  flex-direction:row;
  background: linear-gradient(45deg, #ffffff, #ffffff);
  padding:10px;
  border-radius:3px;
  margin-top:40px;
`



const Cannabis = () => {

useEffect(() => {
        window.scrollTo(0,0)
    })

  const data = useStaticQuery(graphql`
query {
  ttmlogo: file(relativePath: {eq: "ttm-horiz.png"}) {
    childImageSharp {
      fluid(maxWidth:170){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  thcsd: file(relativePath: {eq: "cannabis/insidethcsd.jpg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image1: file(relativePath: {eq: "cannabis/apple_dev.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image2: file(relativePath: {eq: "cannabis/appStore.png"}) {
    childImageSharp {
      fluid(maxWidth:130){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image3: file(relativePath: {eq: "cannabis/googledev.png"}) {
    childImageSharp {
      fluid(maxWidth:130){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image4: file(relativePath: {eq: "cannabis/woocommerce.png"}) {
    childImageSharp {
      fluid(maxWidth:130){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image5: file(relativePath: {eq: "cannabis/wordpress.png"}) {
    childImageSharp {
      fluid(maxWidth:130){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image6: file(relativePath: {eq: "cannabis/bureau.jpg"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image7: file(relativePath: {eq: "cannabis/metric.png"}) {
    childImageSharp {
      fluid(maxWidth:180){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image8: file(relativePath: {eq: "cannabis/secret.png"}) {
    childImageSharp {
      fluid(maxWidth:180){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image9: file(relativePath: {eq: "cannabis/thcsd.png"}) {
    childImageSharp {
      fluid(maxWidth:180){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image10: file(relativePath: {eq: "cannabis/treezlogo.png"}) {
    childImageSharp {
      fluid(maxWidth:180){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }
}`
)  

     



return (
           
   
   <div id="outer-container">
    <SEO/>
      <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      <div id="page-wrap">

      
        
        <div sx={{mt:60,p:20,maxWidth:900,m:'0 auto'}}>
        <a href="/">
          <Img sx={{marginTop:'20px'}}
            fluid={data.ttmlogo.childImageSharp.fluid}
            alt="Touch This Media Logo"
          />   
        </a>
        <h1 sx={{
          color: "heading",
          m: 1,
          mt:80,
          mb:40
        }}>Connected Cannabis Retail </h1>

       

         <Img sx={{m:'0 auto'}}
            fluid={data.thcsd.childImageSharp.fluid}
            alt="cannabis dispensary thcsd"
            style={{ width:`100%`}} 
          />
       
        <div>
        <h2>Solution</h2>
            <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
            The need to acquire & retain customers never ends for dispensaries, brands and manufacturers in the cannabis industry. In this highly competitive market, strong demand does not translate into sales. A consistent digital sales & marketing strategy is essential to excel in the market. </p>
            <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
           Leveraging our expertise in WordPress & WooCommerce, ​we specialize in interfacing with existing digital infrastructures (Treez POS) to create engagement & monetization platforms consisting of <strong> ​online shopping</strong>​,<strong> menu boards​</strong>,<strong> blog content</strong>​ ​& <strong>​Google Ads</strong> services.</p>
           


            <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
              We specialize in providing software as a service built to attract clients & incentivize longer, more frequent visits and increased spend which can come in various forms including, but not limited to
            </p>

            <ul>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faCashRegister}/> E-commerce</li>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faTrophy}/>  Promotional games</li>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faCommentDollar}/>  Digital marketing</li>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faDesktop}/> Digital signage</li>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faAd}/>  Digital advertising</li>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faSearch}/> Search engine optimization</li>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faEnvelopeSquare}/> Email marketing</li>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faSms}/> SMS marketing</li>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faPeopleArrows}/> Social marketing</li>
              <li sx={{listStyleType:`none`, fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color:`text`}}> <FontAwesomeIcon icon={faBlog}/> Organic blog content</li>
            </ul>

            <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
            Our successes include a 1,000% increase in online sales over an 18-month period with one of our brick & mortar retailers, taking their sales from $30K per month to over $300K per month with the use of our full marketing suite. Additionally, through a collection of web development, digital advertising &
organic blog traffic, online sales increased as a percentage of total monthly sales from 3.4% to 24% of total monthly sales.
            </p>

            <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
              Monthly programs start at $1,299 and include hosting, support & maintenance services. 
            </p>

      </div>

      <div>
        <h2>Useful to</h2>
        <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
      Cannabis dispensaries and retailers
        </p>

      </div>

       <div id="ttm-form">
                <h2>Get Connected</h2>
                
               <a href="https://advansr.online">advanSR.online</a>
       </div>

        <ImageWrapper>
           <Img
            fluid={data.image1.childImageSharp.fluid}
            alt="casino trac logo"
            style={{ width:`100%`}} 
          />

          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt="firekeepers casino logo" 
            style={{ width:`100%`}}
          />

          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt="redmile casino logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt="sca logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image5.childImageSharp.fluid}
            alt="sca logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image6.childImageSharp.fluid}
            alt="sca logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image7.childImageSharp.fluid}
            alt="sca logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image8.childImageSharp.fluid}
            alt="sca logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image9.childImageSharp.fluid}
            alt="sca logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image10.childImageSharp.fluid}
            alt="sca logo" 
            style={{ width:`100%`}}
          />
        </ImageWrapper>
        <Sitemap/>
        <div sx={{ m:"0 auto",mt:50,
        width:'100%',alignItems:`center`, textAlign: `center`}}>
         <h4>Touch This Media </h4> 
        <p> <FontAwesomeIcon icon={faAddressBook}/> 3288 Adams Ave <br/>
          P.O. Box 16113 <br/>
          San Diego, CA 92176
        </p>
        <p> <FontAwesomeIcon icon={faPhoneSquareAlt}/> <a href="tel:619-796-2675"> 619-796-2675</a> </p>
        <button sx={{variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`}} type="button"> <FontAwesomeIcon icon={faEnvelopeOpenText}/> <a sx={{color:`background`}}href="mailto:sales@touchthismedia.com"> Email Us </a> </button>
        </div>

    </div>    
    </div>    
    </div>
    
  )    
    

}



export default Cannabis 




